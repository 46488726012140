* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html, body {
    height: 100%;
    font-family: "Times New Roman", Times, serif;
}

.container_login {
    height: 100%;
    width: 100%;
    font-family: "Gill Sans", sans-serif;
}

.grid-login{
    margin: 0 auto;
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-columns: 40% 60%;
    grid-template-areas: "a b";
}
.formsesion{
    grid-area: a;
    background-color: #282c3c;
    padding: 10px;
    text-align: center;
    color: white;
}
.imglogin{
    grid-area: b;
    position: relative;
    overflow: hidden;
}
.imglogin::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1;
}
/* imagen de fondo del login */
.imglogin img{
  display: block;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  z-index: 2;
}
/* logo de la empresa fc */
.logoempresa img{
    padding-top: 10px;
    text-align: center;
    width: 170px;
}

.div-lg1{
    padding-bottom: 50px;
}
.camplogin .div-lg1 .bienvenido{
    padding-top: 10px;
    font-size: 20px;
    font-weight: 200;
}
.grid-form-login{
    margin: 0 auto;
    width: 70%;
}
.camplogin .div-lg1 .text-login{
    font-size: 20px;
    padding-top: 10px;
    color: #9098a9;
    font-weight: 200;
}
.form-user{
    width: 100%;
    margin: 0 auto;
    position: relative;
    padding-bottom: 40px;
}
.form-user input{
    background-color: #282c3c;
    border: 2px solid #ccc;
    padding: 10px;
    width: 100%;
    border-radius: 5px;    
    color: white;
    height: 50px;
}
.label-usuario{
    font-size: 20px;
    font-weight: 100;
    position: absolute;
    background-color: #282c3c;
    top: 0;
    left: 0;
    padding-left: 4px;
    padding-right: 4px;
    margin-left: 10px;
    margin-top: -12px; 
}

.form-pass{
    width: 100%;
    position: relative;
    margin: 0 auto;
    display: grid;
    height: 100%;
    padding-bottom: 30px;
}


.pass2grid{
    position: absolute;
    top: 0;
    right: 0;
} 


.label-contraseña{
    font-size: 20px;
    font-weight: 100;
    position: absolute;
    background-color: #282c3c;
    top: 0;
    left: 0;
    padding-left: 4px;
    padding-right: 4px;
    margin-top: -12px;
    margin-left: 10px;
}
.form-pass input{
    background-color: #282c3c;
    border: 2px solid #ccc;
    padding: 10px;
    width: 100%;
    border-radius: 5px;    
    color: white;
    height: 50px;
}

.boton-login{
    background-color: #5464d3;
    color: white;
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    margin-bottom: 15px;
}
.boton-login:hover{
    cursor: pointer;
}

/* seccion de olvidaste tu contraseña */
.forgot-pass{
    font-size: 17px;
    font-weight: 100;
}
.forgot-pass:hover{
    cursor: pointer;
    text-decoration: underline;
}

/* Booton de ingresar al entrar a web corporativa */
.boton-ingresar{
    margin: 0 auto;
    background-color: #5464d3;
    color: white;
    width: 80%;
    padding: 10px;
    border: none;
    border-radius: 5px;
}
.boton-ingresar:hover{
    cursor: pointer;
}

/* boton registrarse */
.boton-registrarse{
    margin: 0 auto;
    background-color: #43ad6a;
    color: white;
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    margin-bottom: 15px;
}
.boton-registrarse:hover{
    cursor: pointer;
}
/* Registrar usuario */

.logoempresa-register img{
    text-align: center;
    padding-top: 10px;
    width: 100px;
}

.form-register{
    width: 100%;
    margin: 0 auto;
    position: relative;
    padding-bottom: 20px;
}
.form-register input{
    background-color: #282c3c;
    border: 2px solid #ccc;
    padding: 10px;
    width: 100%;
    border-radius: 5px;    
    color: white;
    height: 50px;
}
.div-lg2{
    padding-bottom: 30px;
    padding-top: 20px;
}
.div-lg3{
    padding-top: 10px;
    padding-bottom: 20px;
}
.div-lg3 .bienvenido{
    font-size: 25px !important;
    font-weight: 100;
}

/* cambiar contraseña */
.div-lg4{
    padding-top: 15px;
    padding-bottom: 40px;
    font-size: 20px !important;
    font-weight: 100;
}
.boton-cancelar{
    margin: 0 auto;
    background-color: rgba(220, 25, 25, 0.793);
    color: white;
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    margin-bottom: 15px;
}
.boton-cancelar:hover{
    cursor: pointer;
}

.div-lg5{
    padding-top: 15px;
    padding-bottom: 40px;
    font-size: 20px !important;
    font-weight: 100;
}
.form-register-bloq{
    width: 100%;
    margin: 0 auto;
    position: relative;
    padding-bottom: 30px;    
}
.form-register-bloq .input-usuario{
    background-color: #31314c;
    border: 2px solid #ccc;
    padding: 10px;
    width: 100%;
    border-radius: 5px; 
    font-size: 20px;
    height: 50px;
    color: white;
}
.form-reset-pass{
    width: 100%;
    margin: 0 auto;
    position: relative;
    padding-bottom: 20px;
}
.form-reset-pass input{
    background-color: #282c3c;
    border: 2px solid #ccc;
    padding: 10px;
    width: 100%;
    border-radius: 5px; 
    font-size: 20px;
    height: 50px;
    color: white;
}



@media only screen and (max-width: 920px) {
    .grid-login{
        grid-template-areas: "a a";
    }
    .formsesion{
        height: 100vh;
    }
    .imglogin{
        display: none;

    }
  }


  /* modal cargando */
  #contenedor{ 
    background-color: #282c3c; 
    display: grid;
    place-content: center;
    height: 100vh;
    color: #000;
  }
  .cargando{ 
    position: relative; 
    color: #FFF;
    margin-top: 2em;
    letter-spacing: 0.08em;
    font-size: 30px;
    font-weight: 500;
  }
  
  .contenedor-loader{  
       width: 180px;
      height: 30px;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
      justify-content: space-between;
      margin: 0 auto; 
  }
  .contenedor-loader div{ 
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #FFF;
    animation: salto 1s alternate
      infinite;
  }
  .contenedor-loader div:nth-child(2){ 
    animation-delay: 0.20s; 
  }
  .contenedor-loader div:nth-child(3){ 
    animation-delay: 0.40s; 
  }
  .contenedor-loader div:nth-child(4){ 
    animation-delay: 0.60s; 
  }
  @keyframes salto {
      from {
          opacity: 1;
      }
      to{
           opacity: 0;
      }
  }
  

  @media only screen and (max-width: 920px) {
    .cargando{ 
        font-size: 15px;
    }  
    .contenedor-loader{  
        width: 130px;
    }
   .contenedor-loader div{ 
     width: 20px;
     height: 20px;
    }
  }