/* boton cerrar sesion */
.boton-logout{ 
    padding: 10px;
    color: white;
    background-color: rgba(220, 25, 25, 0.793);
  }
  .boton-logout:hover{
    cursor: pointer;
  }

  .home{
    margin: 0;
    padding-left: 12px;
  }
