.slider-procedimientos{
    width: 100%;
    height: 30vh;
    text-align: center;
    background-image: url("../../imgcomponents/slider-1.png");
    position: relative;
    background-size: 100% 100%;
    background-position: left center; /* La imagen se posicionará a la izquierda y centrada verticalmente */
    overflow-y: hidden;
}

.slider-procedimientos .capa-slider{
    position: absolute;
    bottom: 0;
    left: 0;
    height: 60vh;
    width: 100%;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.4);
}
.slider-procedimientos .text-slider{
    color: #fff;
    font-size: 45px;
    margin-top: 220px;
    font-weight: bold;
    animation-name: slide-up;
    animation-duration: 3s;
    animation-fill-mode: forwards;
}
.slider-procedimientos .text-slider2{
    color: #fff;
    padding-left: 10%;
    padding-right: 10%;
    font-size: 20px;
    font-weight: 100;
    animation-duration: 3s;
    animation-name: slide-up;
    animation-fill-mode: forwards;
}
  @keyframes slide-up {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }




.catalogo-formats{
    width: 100%;
}
.grid-procedures{
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 24% 24% 24% 24%;
    grid-template-areas: 
      "carta1 carta2 carta3 carta4"
      "carta5 carta6 carta7 carta8"
      "carta9 carta10 carta11 carta12";
    grid-gap: 20px;
}
.carta{
    border-radius: 25px;
    box-shadow: 7px 0px 7px rgba(0, 0, 0, 0.3);
    background-color: white;
}

.carta .img-format{
    width: 100%;
    height: 200px;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
}

.carta img:hover{
    cursor: pointer;
}

.descripcion-carta{
    height: 80px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 15px;
}
.descripcion-carta p{
    height: 100%;
    width: 90%;
}
.title-carta{
    font-size: 12px;
    padding-left: 5px;
}
.download-format{
    width: 35px;
    height:35px;
    margin-left: 10px;
    margin-top: -15px;
}
.download-format:hover{
    cursor: pointer;
}

.container-procedures-search {
    position: relative;
    z-index: 800;
    padding: 20px;
    margin: 0 auto;
    width: 50%;
}

.pdf-document{
    padding-right: 50px;
    padding-left: 50px;
    padding-bottom: 20px;
}




@media only screen and (max-width: 1080px) {
    .grid-procedures{
        grid-template-areas: 
        "carta1 carta1 carta2 carta2"
        "carta3 carta3 carta4 carta4"
        "carta5 carta5 carta6 carta6"
        "carta7 carta7 carta8 carta8"
        "carta9 carta9 carta10 carta10" 
        "carta11 carta11 carta12 carta12";
    }
}


@media only screen and (max-width: 480px) {
    .container-procedures{
        padding-left: 0px;
        padding-right: 0px;
    }

    .container-carts-procedures{
        width: 85%;
    }
    .container-procedures-search{
        width: 100%;
    }
    .grid-procedures{
        grid-template-areas: 
          "carta1 carta1 carta1 carta1"
          "carta2 carta2 carta2 carta2"
          "carta3 carta3 carta3 carta3"
          "carta4 carta4 carta4 carta4"
          "carta5 carta5 carta5 carta5"
          "carta6 carta6 carta6 carta6"
          "carta7 carta7 carta7 carta7"
          "carta8 carta8 carta8 carta8"
          "carta9 carta9 carta9 carta9"
          "carta10 carta10 carta10 carta10"
          "carta11 carta11 carta11 carta11"
          "carta12 carta12 carta12 carta12"
    }
    .slider-procedimientos .text-slider{
        font-size: 20px;
    }
    .slider-procedimientos .text-slider2{
        font-size: 15px;
    }
}
