.slider-formatos{
    width: 100%;
    height: 30vh;
    text-align: center;
    background-image: url("../../imgcomponents/slider-1.png");
    position: relative;
    background-size: 100% 100%;
    background-position: left center; /* La imagen se posicionará a la izquierda y centrada verticalmente */
    overflow-y: hidden;
}
.slider-formatos .capa-slider {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 60vh;
    width: 100%;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.4);
}
.slider-formatos .text-slider{
    color: #fff;
    font-size: 50px;
    margin-top: 220px;
    font-weight: bold;
    animation-name: slide-up;
    animation-duration: 3s;
    animation-fill-mode: forwards;
}
.slider-formatos .text-slider2{
    color: #fff;
    padding-left: 10%;
    padding-right: 10%;
    font-size: 22px;
    font-weight: 100;
    animation-duration: 3s;
    animation-name: slide-up;
    animation-fill-mode: forwards;
}
  @keyframes slide-up {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }



.catalogo-formats{
    width: 100%;
}
.grid-formats{
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 22% 22% 22% 22%;
    grid-template-areas: "a b c d";
    grid-gap: 20px;
}
.carta1{
    grid-area: a;
}
.carta2{
    grid-area: b;    
}
.carta3{
    grid-area: c;
}
.carta4{
    grid-area: d;
}

.carta{
    border-radius: 25px;
    box-shadow: 7px 0px 7px rgba(0, 0, 0, 0.3);
    background-color: white;
}

.carta .img-format{
    width: 100%;
    height: 250px;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
}
.descripcion-carta{
    height: 60px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 15px;
}
.descripcion-carta p{
    height: 100%;
    width: 80%;
}
.title-carta{
    font-weight: bold;
    font-size: 15px;
    padding-left: 10px;
}
.download-format{
    width: 35px;
    height:35px;
    margin-left: 10px;
    margin-top: -15px;
}
.download-format:hover{
    cursor: pointer;
}



.drangAndDropExpense{
    width: 100%;
    height: 70px;
    border: 1px solid red;
    margin: 0 auto;
    margin-top: 20px;
}
.upload-area{
  width: 100%;
  white-space: normal !important;
  overflow: hidden;
}

.container-drag{
    width: 400px;
    margin: 0 auto;
}


@media only screen and (max-width: 720px) {
    .grid-formats{
       grid-template-areas: 
       "a a a a"
       "b b b b"
       "c c c c"
       "d d d d";
    }
}