/* parte del slider */
.content-home{
    background-color: white;
}

.slider-home{
    width: 100%;
    height: 40vh;
    text-align: center;
    background-image: url("../imgcomponents/slider-1.png");
    position: relative;
    background-size: 100% 100%;
    background-position: left center; /* La imagen se posicionará a la izquierda y centrada verticalmente */
    overflow-y: hidden;
}
.capa-slider {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 60vh;
    width: 100%;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.4);
 
}
.text-slider{
    color: #fff;
    font-size: 60px;
    margin-top: 160px;
    font-weight: bold;
    animation-name: slide-up;
    animation-duration: 3s;
    animation-fill-mode: forwards;
}
.text-slider2{
    color: #fff;
    padding-left: 10%;
    padding-right: 10%;
    font-size: 25px;
    font-weight: 100;
    animation-duration: 3s;
    animation-name: slide-up;
    animation-fill-mode: forwards;
}
  @keyframes slide-up {
    from {
      transform: translateY(150%);
    }
    to {
      transform: translateY(0);
    }
  }
/* quienes sommos */
.quienes-somos{
    padding-top: 30px;
    text-align: center;
    font-size: 50px;
    color: rgb(62, 62, 62);
    display: flex;
    flex-direction: column;
    align-items: center;
}
.linea{
  width: 60%;
  height: 3px;
  background-color: black;
  margin-top: 10px;
}

.container-history{
    padding-top: 40px;
    margin: 0 auto;
    width: 90%;
    font-weight: 400;
    text-align: justify;
    font-size: 22px;
}


/* secciones de mision valores */

.container-valores{
    padding-top: 50px;
    width: 90%;
    margin: 0 auto;
}
.container-valores .ident{
    width: 100%;
}
.ident .identseccion{
    width: 100%;
    display: flex;
}

.ident .identseccion .ident1{
    font-size: 22px;
    font-weight: 700;
    color: rgb(83, 83, 83);
    padding-right: 10px;
}
.ident .identseccion .linea2{
    width: 150px;
    height: 2px;
    margin-top: 15px;
    background-color: rgba(220, 25, 25, 0.793);
}
.ident .ident2{
    font-size: 35px;
    font-weight: bold;
    color:#880414;
}


/* items valors, mision, vision */

.container-mvv{
    padding-top: 20px;
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 30% 75%;
    grid-template-areas:
    "a b";
    grid-gap:10px;
}
.lista-de-items{
    grid-area: a;
}
.contenido-items{
    grid-area: b;
}

.lista-de-items li{
    text-align: left;
    list-style: none;
    padding: 10px;
    font-size: 25px;
}
.list-select{
    color:#880414;
    border-right: 3px solid #880414;
    font-weight: bold;
}
.lista-de-items li:hover{
    font-size: 25px;
    cursor: pointer;
    color:#880414;
    text-decoration: underline;
}

.title-mision, .title-vision, .title-valores{
    font-size: 25px;
    font-weight: bold;
    color: #880414;
    padding-bottom: 20px;
}

.texto-mvv{
    font-size: 22px;
    text-align: justify;
    padding-bottom: 10px;
}

/* seccion del organigrama */
.container-organigrama{
    padding-top: 50px;
    width: 90%;
    margin: 0 auto;
    position: relative;
}
.organigrama-img{
    width: 100%;
    height: 100%;
    margin: 0 auto;
    text-align: center;
    position: relative;
}
.organigrama-img img{
    width: 100%;
}
.text-orga{
    padding-top: 10px;
    font-size: 22px;
}
/* mapeo de imagenes */
/* area {
    cursor: pointer;
    position: absolute;
    width: 7%;
    height: 6%;
    top: 24%;
    left: 12%;
} */

.personal1{
    cursor: pointer;
    position: absolute;
    width: 7%;
    height: 6%;
    top: 24%;
    left: 12%;
}
.personal2{
    cursor: pointer;
    position: absolute;
    width: 7%;
    height: 6%;
    top: 34%;
    left: 12%;
}
.personal3{
    cursor: pointer;
    position: absolute;
    width: 7%;
    height: 5%;
    top: 45%;
    left: 12%;
}
.personal4{
    cursor: pointer;
    position: absolute;
    width: 7%;
    height: 6%;
    top: 55%;
    left: 12%;
}
.personal5{
    cursor: pointer;
    position: absolute;
    width: 7%;
    height: 6%;
    top: 65%;
    left: 12%;
}
.personal6{
    cursor: pointer;
    position: absolute;
    width: 7%;
    height: 5%;
    top: 76%;
    left: 12%;
}
.personal7{
    cursor: pointer;
    position: absolute;
    width: 5%;
    height: 6%;
    top: 86%;
    left: 13%;
}
.personal8{
    cursor: pointer;
    position: absolute;
    width: 5%;
    height: 5%;
    top: 24.5%;
    left: 76%;
}
.personal9{
    cursor: pointer;
    position: absolute;
    width: 7%;
    height: 5%;
    top: 35%;
    left: 42%;
}
.personal10{
    cursor: pointer;
    position: absolute;
    width: 7%;
    height: 5%;
    top: 35%;
    left: 79%;
}
/* Preventa */
.personal11{
    cursor: pointer;
    position: absolute;
    width: 5%;
    height: 5%;
    top: 55%;
    left: 34%;
}
.personal12{
    cursor: pointer;
    position: absolute;
    width: 5%;
    height: 5%;
    top: 55%;
    left: 43%;
}

.personal13{
    cursor: pointer;
    position: absolute;
    width: 5%;
    height: 5%;
    top: 55%;
    left: 52%;
}
.personal14{
    cursor: pointer;
    position: absolute;
    width: 5%;
    height: 5%;
    top: 55%;
    left: 61.5%;
}
.personal15{
    cursor: pointer;
    position: absolute;
    width: 5%;
    height: 5%;
    top: 55%;
    left: 71%;
}

.personal16{
    cursor: pointer;
    position: absolute;
    width: 5%;
    height: 5%;
    top: 55.2%;
    left: 80%;
}
.personal17{
    cursor: pointer;
    position: absolute;
    width: 5%;
    height: 5%;
    top: 55.2%;
    left: 89.3%;
}
/*Rutas*/
.personal21{
    cursor: pointer;
    position: absolute;
    width: 5%;
    height: 5%;
    top: 65.5%;
    left: 34%;
}
.personal22{
    cursor: pointer;
    position: absolute;
    width: 5%;
    height: 5%;
    top: 65.5%;
    left: 43%;
}

.personal23{
    cursor: pointer;
    position: absolute;
    width: 5%;
    height: 5%;
    top: 65.5%;
    left: 52.5%;
}
.personal24{
    cursor: pointer;
    position: absolute;
    width: 5%;
    height: 5%;
    top: 65.5%;
    left: 61.5%;
}
.personal25{
    cursor: pointer;
    position: absolute;
    width: 5%;
    height: 5%;
    top: 65.5%;
    left: 71%;
}

.personal26{
    cursor: pointer;
    position: absolute;
    width: 5%;
    height: 5%;
    top: 65.5%;
    left: 80%;
}
.personal27{
    cursor: pointer;
    position: absolute;
    width: 5%;
    height: 5%;
    top: 65.5%;
    left: 89.3%;
}







area:hover {
    opacity: 0.5;
}
.informacion {
    position: absolute;
    width: 200px;
    background-color: white;
    display: none;
}
.informacion img{
    width: 100%;
    height: 150px;
}
.informacion h2{
    font-size: 17px;
}
.informacion p{
    font-size: 15px;
}
 
 .informacion.active {
    display: block;
 }


 .footer{
    height: 1080px;
 }

@media only screen and (max-width: 480px) {

.text-slider{
    font-size: 20px;
    margin-top: 50%;
}
.text-slider2{
    padding-left: 10%;
    padding-right: 10%;
    font-size: 17px;
}
.quienes-somos{
    font-size: 30px;
}

.ident .identseccion .ident1{
    font-size: 17px;
}
.ident .ident2{
    font-size: 22px;
}


.container-mvv{
    grid-template-areas:
    "a a"
    "b b";
}
.lista-de-items li{
    font-size: 22px;
}
.lista-de-items li:hover{
    font-size: 22px;
 }

.title-mision, .title-vision, .title-valores{
    font-size: 22px;
}
.texto-mvv{
    font-size: 17px;
}


} 
