/* tema claro  */

body .navbar-content{
    width: 100%;
    height: 70px;
    background-color: #d7d8db;
    padding: 10px;
    position: fixed;
    z-index: 900;
} 
/* tema oscuro */
body.dark .navbar-content{
    background-color: #282c3c;

}
  

.nav-cont{
    height: 70px;
    width: (100%-250px);
}
.title-nav{
    font-size: 18px;
    color: white;
    font-weight: bold;  
}

.grid-nav{
    display: flex;
    width: 80%;
}


.nav1{
    width: 50%;
    margin-left: 20%;
    margin-right: 10%;
}
.nav2{
    margin-top: 10px;
    display: flex;
    align-items: center;
    color: black;
    font-size: 15px;
    float: right;
}
body.dark .nav2{
    color: white;
}
.navbar-content li{
    height: 40px;
    list-style: none;
    display: flex;
    align-items: center;
    margin-top: 5px;
}

.navbar-content .icon{
    min-width: 60px;
    border-radius: 6px;
    color: black;
}
/* Tema dark */
body.dark .navbar-content .icon{
    color: white;
}



.navbar-content .icon{
    min-width: 60px;
    border-radius: 6px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
}

.navbar-content li.search-box{
    border-radius: 6px;
    background-color: var(--primary-color-light);
    cursor: pointer;
    transition: var(--tran-05);
}

.navbar-content li.search-box input{
    height: 100%;
    width: 100%;
    outline: none;
    border: none;
    background-color: var(--primary-color-light);
    color: var(--text-color);
    border-radius: 6px;
    font-size: 17px;
    font-weight: 500;
    transition: var(--tran-05);
}

@media only screen and (max-width: 720px) {
    .grid-nav{
        width: 100%;
    }    
}

@media only screen and (max-width: 480px) {
    .nav1{
        width: 100%;
    }
    .nav2{
        display: none;
    }
}