/*****************/
.alert {
    font-family:  Helvetica, sans-serif;
    font-size: 25px;
    padding: 15px;
    border: 1px solid transparent;
    border-radius: 4px;
    position: absolute;
    z-index: 10;
    bottom: 0;
    right: 0;
    margin-bottom: 40px;
    margin-right: 40px;
    animation-duration: 1s;
    animation-name: animacion-entrada;
}
.alert:hover{
  cursor: pointer;
}

.alert-salida {
    animation-name: animacion-salida;
}
@keyframes animacion-entrada {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}

@keyframes animacion-salida {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(-100%);
    }
}

  .alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }
  .alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }
  .alert-warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
  }
  .alert-info {
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #bee5eb;
  }